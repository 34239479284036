import React, { FC } from 'react';
import type { Person } from 'schema-dts';

import { LdJson } from '../../scripts/LdJson';
import { validSlug, validString } from '../../utils/schema';

interface ArtistSchemaProps {
  description?: string;
  image?: string;
  fullSlug?: string;
  name?: string;
}

export const ArtistSchema: FC<ArtistSchemaProps> = ({
  description,
  fullSlug,
  image,
  name,
}) => {
  const artist: Person = {
    '@type': 'Person',
    'additionalType': 'Artist',
    ...validString('image', image),
    ...validString('primaryImageOfPage', image),
    ...validString('name', name),
    ...validString('description', description),
    ...validSlug('mainEntityOfPage', fullSlug),
    ...validSlug('url', fullSlug),
  };

  return <LdJson schema={artist} />;
};
